
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Nunito Sans", serif;
}
html {
  scroll-behavior: smooth;
}
