
.slider-buttons button{
    border: none;
    padding: 1rem;
    background-color: rgb(96, 86, 165);
    border-radius: 5px;
    cursor: pointer;
    position: absolute;
    top: 50%;
}
.prev-btn{
    left: -20px ;
}
.next-btn{
    right: -20px;
}

.testimonial-content-wrapper .fa-solid{
    font-size: 100px;
    color: rgb(204, 204, 204);
    position: absolute;
}
.fa-quote-left{
    left: 10px;
}
.fa-quote-right{
    bottom: 0;
    right: 0;
}
.slider-parent{
    width: 90%;
}
@media screen and (max-width:576px) {
    .testimonial-content-wrapper .fa-solid{
        display: none;
    }
    .slider-buttons button{
        top: 30%;
    }
    .prev-btn{
        /* left: 50px ; */
        display: none;
    }
    .next-btn{
        /* right: 30px; */
        display: none;
    }
    
}

