.bg-custom-orange {
    background: linear-gradient(90deg, rgba(0,131,200,1) 0%, rgba(65,197,227,1) 100%);
}

.s2-card-img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
}
