.WeAreSigi-parent {
    background: linear-gradient(90deg, rgba(0,131,200,1) 0%, rgba(65,197,227,1) 100%);
}
.WeAreSigi-parent img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-position: center right;
}

@media screen and (min-width:1120px) {
     .WeAreSigi-outer{
        display: flex;
        flex-direction: column;
        justify-content: center;

     }
}