.footer-parent{
    background: linear-gradient(90deg, rgba(0,131,200,1) 0%, rgba(65,197,227,1) 100%);
    /* color: rgb(179, 199, 246);  */
    color: white;
}
.footer-logo{
    height: 70px;
}
.footer-icons-outer{
    display: flex;
    column-gap: 7px;
}
.footer-icons-outer i{
    font-size: 28px;
    cursor: pointer;
}

.footer-icons-outer a, .copyright a{
    text-decoration: none ;
    color: #fff ;
}



@media screen and (max-width:991px) {
    
    .footer-parent .row{
      text-align: center;
    }   
    .footer-icons-outer{
        justify-content: center;
    }
    .copyright a{
        display: block;
    }
    .copyright span{
        display: none;
    }
}
