
.nav-parent{
  position: relative;
  z-index: 100;
}

/* navbar.css */
.nav-link {
  cursor: pointer;
}

.nav-btn {
  background-color: rgb(0, 74, 234);
  color: white;
  height: 50px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-btn:hover {
  background-color: white;
  color: rgb(0, 74, 234);
}

.navList-outer .nav-link {
  color: white !important;
  font-weight: 600;
  font-size: 1.2rem;
}

@media (max-width: 991.98px) {
  .navbar-toggler {
    background-color: white;
    border: 1px solid #ddd;
  }
}
