.Contact-Us{
    position: relative;
    margin-top: -150px;
}
.contactUs-outer{
    max-width: 1480px !important;
    background-color: #F5F7FF;
}


@media screen and (max-width:767px) {
    .contactUs-outer,.form-outer{
      width: 90% !important;
    }
}