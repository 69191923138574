.product-after-bar{
  position: relative;
}
.product-after-bar::after{
  content: '';
  border: 3px solid white;
  width: 120px;
  position: absolute;
  top: 115%;
  left: 0px;
}
.product-card-img-outer{
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-card-img-outer img {
  width: 100%; 
  height: 100%; 
}

@media screen and (max-width: 991px) {
  /* .product-card-img-outer img {
    width: 10% !important; 
    height: 150% !important;
  } */
}


@media screen and (max-width: 480px) {
  .product-after-bar::after{
    top: 110%;
  }
}
