.home-parent {
  background: url("../../assests/home/back-ground-img.png") center / cover
    no-repeat;
  padding-bottom: 60px;
  background-repeat: no-repeat;
}
.icons {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.home-icon2 {
  display: none;
}
.home-btns {
  background-color: white;
  color: rgb(0, 74, 234);
  height: 50px;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  font-weight: 700;
}
.home-Heading {
  font-weight: 700;
}
.home-studies-btn {
  background-color: transparent !important;
  color: rgb(255, 125, 74) !important;
  border: 1px solid rgb(255, 125, 74);
}
.home-studies-btn:hover {
  border: 1px solid rgb(255, 125, 74) !important;
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Keyframes for fade-in-out animation */
@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Apply infinite animation */
.fade-in-out {
  animation: fadeInOut 4s infinite;
}

.home-btns-outer {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.home-btns-outer .box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px; 
  height: 45px; 
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  overflow: hidden; 
}

.home-btns-outer .box img {
  object-fit: contain; 
}
.gpt{
  height: 28px !important;
}
.claud{
   width: 85px !important;
}
.gemini{
  height: 23px !important;
  margin-bottom: 6px;
}
.plexity{
   width: 90px !important;
}