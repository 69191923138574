.Tech-Stack-parent{
 width: 100%;
 background-image: url('../../assests/techstacks/tech_stack_bg.jpg');
background-repeat: no-repeat;
background-size: cover;
}
/* Grid Layout for TechStacks */
.techStackGrid {
 display: grid;
 grid-template-columns: repeat(6, 1fr);
 grid-gap: 20px;
 width: 90%;
 /* max-width: 1376px; */
 margin: 60px auto 40px;
}

.techBox {
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 height: 150px;
 border-radius: 8px;
 overflow: hidden;
 text-align: center;
}

.techImage {
 height: 90px;
 object-fit: contain;
 margin-bottom: 10px;
}

.techName {
 font-size: 16px;
 font-weight: bold;
 color: #333;
}

/* Style for the buttons */
.categoryButtons {
 display: flex;
 margin-bottom: 20px;
}

.Tech-Stack-parent button {
 padding: 10px 20px;
 font-size: 16px;
 cursor: pointer;
 background-color: transparent;
 color: white;
 border: 1px solid white;
 border-radius: 0px;
}

.Tech-Stack-parent button:hover {
 background-color: white;
 color: rgb(0, 74, 234);
}
.techBox:hover {
 transform: scale(1.05);
}
.bg-yellow {
    background-color:white !important;
    border-color: white !important;
    color: rgb(0, 74, 234) !important;

  }
  
@media (max-width: 768px) {
 .techStackGrid {
  grid-template-columns: repeat(3, 1fr);
  /* 3 items per row */
 }
}

@media (max-width: 576px) {
 .techStackGrid {
  grid-template-columns: repeat(2, 1fr);
  /* 2 items per row */
 }
}
